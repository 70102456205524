/*export const COLORS = [
  '#FF6384', // Soft Red
  '#36A2EB', // Soft Blue
  '#FFCE56', // Soft Yellow
  '#4BC0C0', // Soft Teal
  '#9966FF', // Soft Purple
  '#FF9F40', // Soft Orange
  '#FFCD56', // Soft Gold
  '#4D5360', // Soft Gray
  '#C9CBCF', // Soft Light Gray
  '#36A3EB', // Soft Sky Blue
]*/

export const COLORS = [
  '#1e40af',
  '#3b82f6',
  '#93c5fd',
  '#047857',
  '#22c55e',
  '#86efac',
  '#5b21b6',
  '#8b5cf6',
  '#c4b5fd',
  '#d97706',
  '#fbbf24',
  '#fcd34d',
  '#db2777',
  '#f472b6',
  '#f9a8d4',
]

export const COLORS_REVERSED = ['#22c55e', '#047857', '#93c5fd', '#3b82f6', '#1e40af']
