import { ListValues } from '@/client/backend/models'

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'

type CardLongListProps = {
  item: ListValues
  title: string
  description: string
}

const CardLongList = ({ item, title, description }: CardLongListProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent>
        <ul className="space-y-2">
          {item.values.sort().map((value, index) => {
            return <li key={index}>{value}</li>
          })}
        </ul>
      </CardContent>
    </Card>
  )
}

export default CardLongList
