import { Habitat } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ColumnDef } from '@tanstack/react-table'
import { t } from 'i18next'

import { DataTable } from '@/components/data-table/data-table'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'

interface HabitatTableProps {
  data: Habitat[]
}

const HabitatTable = ({ data }: HabitatTableProps) => {
  const columns: ColumnDef<Habitat>[] = [
    {
      accessorKey: 'code',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.beeoimpact.biodiversityTable.habitat.code)} />
      ),
      cell: ({ row }) => {
        return <span className="max-w-[500px] truncate font-medium">{row.original.code}</span>
      },
    },
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.beeoimpact.biodiversityTable.habitat.name)} />
      ),
      cell: ({ row }) => {
        return <span className="max-w-[150px] overflow-hidden font-medium">{row.original.name}</span>
      },
    },
  ]
  return <DataTable columns={columns} data={data} filterIdColumn="name" />
}

export default HabitatTable
