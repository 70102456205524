import { useContext, useEffect, useMemo, useState } from 'react'
import { useActionsTakenUpdate } from '@/client/backend/api/actions-taken/actions-taken'
import { ActionTaken, Audit, Initiative, Question, Response, Site } from '@/client/backend/models'
import { BeeoinitiativeContext, BeeoinitiativeContextType } from '@/context/beeoinitiative-context'
import { i18nKeys } from '@/locales/keys'
import BreadcrumbComponent from '@/pages/beeoinitiative/breadcrumb-component'
import AuditCard from '@/pages/beeoinitiative/site-detail/audit-card'
import EvaluateRisksOpportunitiesCard from '@/pages/beeoinitiative/site-detail/evaluate-risks-opportunities-card'
import GreenCard from '@/pages/beeoinitiative/site-detail/green-card'
import InitCardsCarousel from '@/pages/beeoinitiative/site-detail/init-cards-carousel'
import NoInitiativesCard from '@/pages/beeoinitiative/site-detail/no-initiatives-card'
import SiteCategoryBadge from '@/pages/beeoinitiative/site-detail/site-category-badge'
import TakenPlannedInitiativesCard from '@/pages/beeoinitiative/site-detail/taken-planned-initiatives'
import { useTranslation } from 'react-i18next'
import { Link, useLoaderData } from 'react-router-dom'

import { getSiteCenter } from '@/lib/geo'
import { filterInitiativeOnSiteResponses } from '@/lib/initiative'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Card } from '@/components/ui/card'
import CustomOutlineButton from '@/components/ui/custom-outline-button'
import { Separator } from '@/components/ui/separator'
import { useToast } from '@/components/ui/use-toast'
import SiteMap from '@/components/beeoinitiative/site-map'
import { Icons } from '@/components/icons'
import Loader from '@/components/loader'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'

interface TakenInitiativePageData {
  site: Site
  actionsTaken: ActionTaken[]
  questions: Question[]
  responses: Response[]
  initiatives: Initiative[]
  audits: Audit[]
}

const TakenInitiativePage = () => {
  const { t } = useTranslation()
  const { site, actionsTaken, questions, responses, initiatives, audits } =
    useLoaderData() as Awaited<TakenInitiativePageData>

  const [seeMoreCard, setSeeMoreCard] = useState(false)
  const [actionTakenDone, setActionTakenDone] = useState(actionsTaken?.filter((action) => action.status === 'DONE'))
  const [actionTakenPlanned, setActionTakenPlanned] = useState(
    actionsTaken?.filter((action) => action.status === 'PENDING')
  )
  const { newActionTaken, resetNewActionTaken } = useContext(BeeoinitiativeContext) as BeeoinitiativeContextType

  const mutation = useActionsTakenUpdate()
  const { toast } = useToast()
  const initiativesCount = useMemo(() => {
    if (initiatives && responses) {
      return initiatives?.filter((init) => filterInitiativeOnSiteResponses(init, responses)).length
    } else {
      return 0
    }
  }, [initiatives, responses])

  const [center, setCenter] = useState<L.LatLng | null>(null)

  useEffect(() => {
    const siteCenter = getSiteCenter(site)
    setCenter(siteCenter)
  }, [site])

  useEffect(() => {
    if (newActionTaken) {
      setActionTakenPlanned((prev) => {
        // Verify if the new action is already in the list
        if (!prev.some((action) => action.id === newActionTaken.id)) {
          return [...prev, newActionTaken]
        }
        return prev
      })
      resetNewActionTaken()
    }
  }, [newActionTaken])

  if (!site) {
    return <Loader />
  }
  const spentBudget = actionsTaken.map((action) => Number(action.spent_budget) || 0).reduce((a, b) => a + b, 0)
  const plannedCost = actionsTaken.map((action) => Number(action.planned_cost) || 0).reduce((a, b) => a + b, 0)

  const moveToDone = (initiativeId: number) => {
    // First, find the initiative to move
    const initiativeToMove = actionTakenPlanned.find((initiative) => initiative.id === initiativeId)

    if (!initiativeToMove) {
      return
    }

    // Optimistically update the state
    setActionTakenPlanned((prevPlanned) => prevPlanned.filter((initiative) => initiative.id !== initiativeId))
    setActionTakenDone((prevDone) => [...prevDone, initiativeToMove])

    mutation.mutate(
      {
        id: initiativeId.toString(),
        data: { status: 'DONE' },
      },
      {
        onError: () => {
          // Revert the state changes if mutation fails
          setActionTakenPlanned((prevPlanned) => [...prevPlanned, initiativeToMove])
          setActionTakenDone((prevDone) => prevDone.filter((initiative) => initiative.id !== initiativeId))
          toast({ title: t(i18nKeys.beeoinitiative.site.error) })
        },
      }
    )
  }

  const siteCategory = {
    URBAN: t(i18nKeys.beeoinitiative.initiativesPage.siteCategory.urbanZone),
    AGRICULTURAL: t(i18nKeys.beeoinitiative.initiativesPage.siteCategory.agriculturalZone),
    FORESTER: t(i18nKeys.beeoinitiative.initiativesPage.siteCategory.forestZone),
    HYBRID: t(i18nKeys.beeoinitiative.initiativesPage.siteCategory.mixedZone),
  }

  const impactScoring = actionsTaken
    .map((action) => action.initiative)
    .map((initiative) => initiative.impact)
    .reduce((a, b) => (a ?? 0) + (b ?? 0), 0)

  const latestAudit = audits
    .filter((audit) => audit.date)
    .sort((a, b) => Number(a.date ?? 0) - Number(b.date ?? 0))
    .slice(-1)[0]

  const filteredInitsBasedOnResponses = initiatives.filter((initiative) =>
    filterInitiativeOnSiteResponses(initiative, responses)
  )

  return (
    <div className="px-11">
      <BreadcrumbComponent
        breadcrumbItems={[
          {
            label: t(i18nKeys.beeoinitiative.dashboard.breadcrumb.beeoapps),
            href: '/',
          },
          {
            label: t(i18nKeys.beeoinitiative.dashboard.breadcrumb.beeoinitiative),
            href: getPath(Routes.BEEOINITIATIVE),
          },
          {
            label: t(i18nKeys.beeoinitiative.common.breadcrumb.sites),
            href: getPath(Routes.BEEOINITIATIVE_SITES),
          },
        ]}
        currentPage={site?.properties?.name}
      />
      <div className="flex items-center justify-between">
        <div className="flex items-center  space-x-4">
          <PageHeader>
            <PageHeaderHeading className="hidden md:block">{site.properties?.name}</PageHeaderHeading>
          </PageHeader>
          <SiteCategoryBadge
            category={
              siteCategory[site.properties?.environment_category ?? ''].charAt(0)?.toUpperCase() +
              siteCategory[site.properties?.environment_category ?? ''].slice(1)
            }
          />
        </div>
        <div className="pb-4">
          <Link to={getPath(Routes.BEEOINITIATIVE_INITIATIVES_BY_SITE, { params: { siteId: site.id } })}>
            <CustomOutlineButton
              icon={<Icons.PlusCircleIcon />}
              label={t(i18nKeys.beeoinitiative.site.newInitiative)}
            />
          </Link>
        </div>
      </div>

      <div>
        <div className="flex sm:flex-col md:flex-col md:space-x-0 md:space-y-5  lg:flex-row lg:space-x-5 lg:space-y-0 xl:flex-row xl:space-x-5 xl:space-y-0 2xl:flex-row 2xl:space-x-5 2xl:space-y-0">
          <Card className="flex grow flex-row items-center justify-between sm:h-[170px] sm:w-[512px] md:h-[170px] md:w-full lg:h-[170px] lg:w-[512px] xl:h-[170px] xl:w-[840px] 2xl:h-[170px] 2xl:w-[1180px]">
            <div className="flex flex-1 flex-col items-center justify-center space-y-4">
              <p className="text-xl font-semibold">{t(i18nKeys.beeoinitiative.site.initiatives)}</p>
              <p className="text-4xl font-semibold text-biodivTealSevenHundred">
                {actionTakenDone.length}/{actionsTaken.length}
              </p>
            </div>
            <Separator orientation="vertical" className="h-3/5" />
            <div className="flex flex-1 flex-col items-center justify-center">
              <div className="flex items-center space-x-2">
                <Icons.Euro className="size-4 text-biodivTealSevenHundred" />
                <p className="pb-1 text-xl font-semibold">{t(i18nKeys.beeoinitiative.site.totalBudget)}</p>
              </div>
              <p className="pb-2">{t(i18nKeys.beeoinitiative.site.spentVSplanned)}</p>
              <p className="text-xl font-semibold text-biodivTealSevenHundred">
                {spentBudget ?? 0}/{plannedCost ?? 0} €
              </p>
            </div>
            <Separator orientation="vertical" className="h-3/5" />
            <div className="flex flex-1 shrink flex-col items-center justify-center space-y-4">
              <div className="flex flex-row items-center space-x-2">
                <Icons.Leaf className="size-4 text-biodivTealSevenHundred" />
                <p className="text-xl font-semibold">Score</p>
              </div>
              <p className="text-4xl font-semibold text-biodivTealSevenHundred">
                {impactScoring} {t(i18nKeys.beeoinitiative.site.pts)}
              </p>
            </div>
          </Card>
          {center && (
            <div className="sm:h-[170px] sm:w-[164px] md:h-[170px] md:w-full lg:h-[170px] lg:w-[164px] xl:h-[170px] xl:w-[252px] 2xl:h-[170px] 2xl:w-[383px]">
              <SiteMap target={center} site={site} />
            </div>
          )}
        </div>
        <div className="py-4">
          <InitCardsCarousel
            site={site}
            filteredInitsBasedOnResponses={filteredInitsBasedOnResponses}
            initiativesCount={initiativesCount}
            questions={questions}
            responses={responses}
          />
        </div>

        <div className="xs:flex-col flex justify-center pb-8 sm:space-y-5 md:flex-col md:space-y-5  lg:flex-col lg:space-y-5 xl:flex-row xl:space-x-5  xl:space-y-0 2xl:flex-row 2xl:space-x-5 2xl:space-y-0">
          {actionTakenDone.length ? (
            <TakenPlannedInitiativesCard
              title={
                actionTakenDone.length +
                ' ' +
                t(i18nKeys.beeoinitiative.site.takenInitiative, {
                  count: actionTakenDone.length,
                })
              }
              initiativeList={actionTakenDone}
              footer={seeMoreCard ? t(i18nKeys.beeoinitiative.site.seeLess) : t(i18nKeys.beeoinitiative.site.seeMore)}
              button={false}
              seeMoreCard={seeMoreCard}
              setSeeMoreCard={setSeeMoreCard}
              onMoveToDone={moveToDone}
              site={site}
              setActionTakenDone={setActionTakenDone}
              actionTakenDone={actionTakenDone}
            />
          ) : (
            <NoInitiativesCard
              title={`${actionTakenDone.length} ${t(i18nKeys.beeoinitiative.site.takenInitiative, { count: actionTakenDone.length })}`}
              message={
                <>
                  <span>{t(i18nKeys.beeoinitiative.site.noTakenInits.text1)}</span>
                  <span className="font-semibold text-biodivTealSevenHundred">
                    {t(i18nKeys.beeoinitiative.site.noTakenInits.text2)}
                  </span>
                  <span>{t(i18nKeys.beeoinitiative.site.noTakenInits.text3)}</span>
                </>
              }
            />
          )}

          {actionTakenPlanned.length ? (
            <TakenPlannedInitiativesCard
              title={
                actionTakenPlanned.length +
                ' ' +
                t(i18nKeys.beeoinitiative.site.plannedInitiative, {
                  count: actionTakenPlanned.length,
                })
              }
              initiativeList={actionTakenPlanned}
              footer={seeMoreCard ? t(i18nKeys.beeoinitiative.site.seeLess) : t(i18nKeys.beeoinitiative.site.seeMore)}
              button={true}
              seeMoreCard={seeMoreCard}
              setSeeMoreCard={setSeeMoreCard}
              onMoveToDone={moveToDone}
              site={site}
              setActionTakenDone={setActionTakenDone}
              actionTakenDone={actionTakenDone}
            />
          ) : (
            <NoInitiativesCard
              title={`${actionTakenPlanned.length} ${t(i18nKeys.beeoinitiative.site.plannedInitiative, { count: actionTakenPlanned.length })}`}
              message={
                <>
                  <span className="font-semibold text-biodivTealSevenHundred">
                    {t(i18nKeys.beeoinitiative.site.NoPlannedInits.text1)}
                  </span>
                  <span>{t(i18nKeys.beeoinitiative.site.NoPlannedInits.text2)}</span>
                </>
              }
              button={
                <CustomOutlineButton
                  icon={<Icons.PlusCircleIcon />}
                  label={t(i18nKeys.beeoinitiative.site.newInitiative)}
                />
              }
              buttonLink={getPath(Routes.BEEOINITIATIVE_INITIATIVES_BY_SITE, { params: { siteId: site.id } })}
            />
          )}
        </div>
        {actionTakenPlanned.length !== 0 && actionTakenDone.length !== 0 ? (
          <div className="grid grid-cols-2 gap-5 pb-4 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2">
            <AuditCard latestAudit={latestAudit} />
            <GreenCard site={site} />
          </div>
        ) : (
          <div className="pb-8">
            <EvaluateRisksOpportunitiesCard
              icon={<Icons.GreenCardIcon size={120} />}
              title={t(i18nKeys.beeoinitiative.site.imageText.title)}
              description1={t(i18nKeys.beeoinitiative.site.imageText.description1)}
              description2={t(i18nKeys.beeoinitiative.site.imageText.description2)}
              buttonText={t(i18nKeys.beeoinitiative.dashboard.evaluateRisks.button)}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default TakenInitiativePage
