import { getSitesHabitatsListQueryKey, sitesHabitatsList } from '@/client/backend/api/sites/sites'
import { CLCPlot } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import generalAnalysisLoader from '@/pages/beeoimpact/general-analysis/general-analysis.loader'
import { useQuery } from '@tanstack/react-query'
import { LatLng } from 'leaflet'
import { HomeIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useLoaderData, useParams } from 'react-router'

import { getGbifMarkers } from '@/lib/gbif'
import { getSiteCenter } from '@/lib/geo'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Separator } from '@/components/ui/separator'
import CallToACtion from '@/components/beeoimpact/sites/cta'
import ClcTable from '@/components/beeoimpact/tables/clc-table'
import HabitatTable from '@/components/beeoimpact/tables/habitat-table'
import SpeciesGbifTable from '@/components/beeoimpact/tables/species-gbif-table'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@/components/breadcrumb'
import DataTableSkeleton from '@/components/data-table/data-table-skeleton'
import ClcGbifMap from '@/components/map'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'
import { H3 } from '@/components/typography'

export interface GbifMarker {
  id: number
  name: string
  marker: LatLng
  category: string
  clc: CLCPlot[]
}

export interface ZoneNamesColor {
  zone: string
  color: string
  name: string
  surface: number
  percentage: number
}
const GeneralAnalysisPage = () => {
  const { t } = useTranslation()
  const { site, gbif, clc } = useLoaderData() as Awaited<ReturnType<typeof generalAnalysisLoader>>

  const { siteId } = useParams<{ siteId: string }>() as { siteId: string }

  const { data: habitats } = useQuery({
    queryKey: [getSitesHabitatsListQueryKey(siteId)],
    queryFn: () => sitesHabitatsList(siteId, undefined),
  })

  const center = getSiteCenter(site)

  const getMarkers = getGbifMarkers(gbif)

  const zoneGroups = {
    1: t(i18nKeys.beeoimpact.site.clcTypes.urban),
    2: t(i18nKeys.beeoimpact.site.clcTypes.agricultural),
    3: t(i18nKeys.beeoimpact.site.clcTypes.vegetation),
    4: t(i18nKeys.beeoimpact.site.clcTypes.wetAreas),
    5: t(i18nKeys.beeoimpact.site.clcTypes.waterBodies),
  }

  const newObjZoneNamesColor = clc.reduce((acc, curr) => {
    const firstDigit = curr.type.type_code_level1?.toString().charAt(0) ?? '0'
    const existingObj = acc.find((obj) => obj.name === curr.type.name_level3)

    if (existingObj) {
      existingObj.surface += (curr.intersection_area_sq_m ?? 0) / 10000
    } else {
      const newObj = {
        zone: zoneGroups[firstDigit],
        color: curr.type.color,
        name: curr.type.name_level3 ?? '',
        surface: (curr.intersection_area_sq_m ?? 0) / 10000,
        percentage: 0,
      }
      acc = [...acc, newObj]
    }
    return acc.sort((a, b) => b.surface - a.surface)
  }, [] as ZoneNamesColor[])

  const totalSurfaceArea = newObjZoneNamesColor.reduce((acc, curr) => {
    return acc + curr.surface
  }, 0)

  newObjZoneNamesColor.forEach((c) => {
    return (c.percentage = totalSurfaceArea !== 0 ? (c.surface / totalSurfaceArea) * 100 : 0)
  })

  return (
    <div className="container relative my-8">
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">
            <HomeIcon className="size-4" />
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href={getPath(Routes.BEEOIMPACT_SITES)}>
            {t(i18nKeys.beeoimpact.common.breadcrumb.mySites)}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href={getPath(Routes.BEEOIMPACT_SITE, { params: { siteId: site.id } })}>
            {site.properties?.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>{t(i18nKeys.beeoimpact.common.breadcrumb.potential)}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <div className="mb-4 flex flex-col justify-between">
        <PageHeader>
          <PageHeaderHeading>{t(i18nKeys.beeoimpact.common.breadcrumb.potential)}</PageHeaderHeading>
        </PageHeader>
        <div className="mb-4">
          <CallToACtion
            href={`mailto:info@beeodiversity.com?Subject=Actions in favor of biodiversity for my site of ${site.properties?.name}`}
          />
        </div>
      </div>

      <div className="mb-4 flex flex-col justify-center">
        <div className=" h-[30rem] w-full rounded-md">
          <ClcGbifMap center={center} gbifMarkers={getMarkers} clc={clc} />
        </div>
        <div>
          <div>
            <H3 className="mb-4">{t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.table.species_gbif)}</H3>
            <Separator />
          </div>
          <div className="mt-8">
            <SpeciesGbifTable data={gbif} site={site} />
          </div>
        </div>

        <div>
          <H3 className="mb-4">{t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.table.clc)}</H3>
          <Separator />
        </div>
        <div className="mt-8">
          <ClcTable data={newObjZoneNamesColor} site={site} />
        </div>
        <div>
          <H3 className="mb-4">{t(i18nKeys.beeoimpact.biodiversityTable.habitat.title)}</H3>
          <Separator />
        </div>
        <div className="mt-8">
          {habitats ? <HabitatTable data={habitats} /> : <DataTableSkeleton columns={2} rows={5} />}
        </div>
      </div>
    </div>
  )
}

export default GeneralAnalysisPage
