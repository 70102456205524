import { useActionsTakenUpdate } from '@/client/backend/api/actions-taken/actions-taken'
import { ActionTaken } from '@/client/backend/models/actionTaken'
import { Initiative } from '@/client/backend/models/initiative'
import { Site } from '@/client/backend/models/site'
import { i18nKeys } from '@/locales/keys'
import { CostInputField } from '@/pages/beeoinitiative/form-create-action-taken'
import InitiativeCard from '@/pages/beeoinitiative/initiatives/initiative-card'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Separator } from '@/components/ui/separator'
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'

interface FormClosePlannedActionProps {
  initiative: Initiative
  actionTaken: ActionTaken
  site: Site
  onMoveToDone: (id: number) => void
  setActionTakenDone: (value: ActionTaken[]) => void
  actionTakenDone: ActionTaken[]
}
const FormClosePlannedAction = ({
  initiative,
  site,
  actionTaken,
  onMoveToDone,
  setActionTakenDone,
  actionTakenDone,
}: FormClosePlannedActionProps) => {
  const FormSchema = z.object({
    cost: z.coerce.number().int().positive(),
  })
  const { t } = useTranslation()
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  })

  const mutation = useActionsTakenUpdate()

  const onSubmit = (data: z.infer<typeof FormSchema>) => {
    mutation.mutate(
      {
        id: actionTaken.id.toString(),
        data: { spent_budget: data.cost.toString(), status: 'DONE' },
      },
      {
        onSuccess: (e) => {
          onMoveToDone(actionTaken.id)
          const newActionTaken = {
            id: e.id,
            date_start: e.date_start,
            status: e.status,
            planned_cost: e.planned_cost,
            initiative: initiative,
            initiative_id: e.initiative_id,
            owner: e.owner,
            site: e.site,
            spent_budget: e.spent_budget,
          }
          setActionTakenDone([...actionTakenDone, newActionTaken])
        },
        onError: () => {},
      }
    )
  }

  const CostInputFieldReal = ({ form }) => {
    return (
      <FormField
        control={form.control}
        name="cost"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-semibold">{t(i18nKeys.beeoinitiative.site.closeSheet.realCost)}</FormLabel>
            <FormControl>
              <Input placeholder="€" {...field} autoFocus />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    )
  }

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline" className={`border-black font-semibold`}>
          {t(i18nKeys.beeoinitiative.site.close)}
        </Button>
      </SheetTrigger>
      <SheetContent className="min-w-[470px]">
        <SheetHeader>
          <SheetTitle className="mb-4 flex items-end gap-3">
            <span className="text-xl font-semibold">{t(i18nKeys.beeoinitiative.site.closeSheet.title)}</span>
          </SheetTitle>
          <Separator />
        </SheetHeader>

        <div className="mt-9 max-h-[calc(100vh-160px)] overflow-y-auto px-3">
          <p className="font-semibold">
            {t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.selectedInitiative)}
          </p>
          <div className="mb-4 mt-3 max-w-[357px]">
            <InitiativeCard initiative={initiative} site={site} buttons={false} cardHeight="h-[350px]" />
          </div>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <CostInputField form={form} showDisabledInput={true} value={actionTaken.planned_cost ?? ''} />
              <CostInputFieldReal form={form} />
              <div className="pt-14">
                <Separator />
              </div>
              <SheetFooter>
                <SheetClose asChild>
                  <Button variant="outline" type="submit" className="w-full border-black font-semibold">
                    {t(i18nKeys.beeoinitiative.site.closeSheet.button)}
                  </Button>
                </SheetClose>
              </SheetFooter>
            </form>
          </Form>
        </div>
      </SheetContent>
    </Sheet>
  )
}

export default FormClosePlannedAction
