import { useContext, useEffect, useState } from 'react'
import { useSitesActionsTakenCreate, useSitesList, useSitesRetrieve } from '@/client/backend/api/sites/sites'
import { ActionTaken, Site, SitesListParams } from '@/client/backend/models'
import { Initiative } from '@/client/backend/models/initiative'
import { BeeoinitiativeContext, BeeoinitiativeContextType } from '@/context/beeoinitiative-context'
import { i18nKeys } from '@/locales/keys'
import MonthlySchedule from '@/pages/beeoinitiative/date-planning'
import { zodResolver } from '@hookform/resolvers/zod'
import { format } from 'date-fns'
import i18next, { t } from 'i18next'
import { CalendarIcon, CalendarPlus, Check, ChevronDown, Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import { DEFAULT_INITIATIVE_CATEGORY, INITIATIVE_CATEGORIES } from '@/lib/initiative'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Card, CardDescription, CardTitle } from '@/components/ui/card'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Separator } from '@/components/ui/separator'
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'
import CategoryIcon from '@/components/beeoinitiative/category-icon'

const FormSchema = z.object({
  date: z.date(),
  site: z.number(),
  cost: z.coerce.number().int().positive(),
})

export const FormCreateActionTaken = ({
  initiative,
  currentSiteId,
  buttonWidth,
}: {
  initiative: Initiative
  currentSiteId?: number
  buttonWidth: string
}) => {
  const params: SitesListParams = {
    is_in_initiative: true,
    page_size: 6,
  }
  const [open, setOpen] = useState(false)

  const siteRetrieve = useSitesRetrieve(currentSiteId?.toString() ?? '')
  const siteListData = useSitesList(params)

  const mutation = useSitesActionsTakenCreate()
  const { saveNewActionTaken } = useContext(BeeoinitiativeContext) as BeeoinitiativeContextType

  const siteList =
    currentSiteId && siteRetrieve.data
      ? [{ label: siteRetrieve.data.properties?.name, value: siteRetrieve.data.id }]
      : siteListData?.data?.results?.features?.map((site: Site) => ({
          label: site.properties?.name,
          value: site.id,
        })) ?? []

  const siteListIsLoading = currentSiteId ? siteRetrieve.isLoading : siteListData.isLoading

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  })

  const onSubmit = (data: z.infer<typeof FormSchema>) => {
    mutation.mutate(
      {
        id: data.site.toString(),
        data: {
          date_start: data.date.toISOString(),
          initiative: initiative.id,
          status: 'PENDING',
          planned_cost: data.cost.toString(),
        },
      },
      {
        onSuccess: (e) => {
          setOpen(false)
          const actionTaken = e as unknown as ActionTaken
          const newActionTaken = {
            id: actionTaken.id,
            date_start: actionTaken.date_start,
            status: actionTaken.status,
            planned_cost: actionTaken.planned_cost,
            initiative: initiative,
            date_done: actionTaken.date_done,
            initiative_id: actionTaken.initiative_id,
            owner: actionTaken.owner,
            site: actionTaken.site,
            spent_budget: actionTaken.spent_budget,
          }
          saveNewActionTaken(newActionTaken)
        },
        onError: () => {},
      }
    )
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant="outline" className={`${buttonWidth} border-black font-semibold`}>
          {t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.plan)}
        </Button>
      </SheetTrigger>
      <SheetContent className="min-w-[470px]">
        <SheetHeader>
          <SheetTitle className="mb-4 flex items-end gap-3">
            <CalendarPlus className="size-8 text-biodivLimeFiveHundred" />
            <span className="text-xl font-semibold">
              {t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.planAnInitiative)}
            </span>
          </SheetTitle>
          <Separator />
        </SheetHeader>

        <div className="mt-9 max-h-[calc(100vh-160px)] overflow-y-auto px-3">
          <p className="font-semibold">
            {t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.selectedInitiative)}
          </p>
          <div className="mb-10 mt-3 max-w-[357px]">
            <InitiativeCard initiative={initiative} />
          </div>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              {!siteListIsLoading && <SiteSelectionField siteList={siteList} form={form} defaultSite={currentSiteId} />}
              <DateSelectionField form={form} />
              <CostInputField form={form} showDisabledInput={false} />

              <Separator />

              <Button
                variant="outline"
                type="submit"
                disabled={mutation.isPending}
                className="w-full border-black font-semibold"
              >
                {mutation.isPending && <Loader2 className="mr-2 size-4 animate-spin" />}
                {t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.plan)}
              </Button>
            </form>
          </Form>
        </div>
      </SheetContent>
    </Sheet>
  )
}

const SiteSelectionField = ({
  siteList,
  form,
  defaultSite,
}: {
  siteList: { label: string | undefined; value: number }[]
  form
  defaultSite?: number
}) => {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [filteredSites, setFilteredSites] = useState(siteList)

  // Define the parameters for useSitesList based on the search query
  const params: SitesListParams = {
    is_in_initiative: true,
    page_size: 4,
    search: searchQuery,
  }

  // Fetch the site list based on the current search query
  const siteListData = useSitesList(params)

  useEffect(() => {
    if (defaultSite && !form.getValues('site')) {
      form.setValue('site', defaultSite)
    }
  }, [defaultSite, form])

  useEffect(() => {
    if (searchQuery.length > 0 && siteListData.data) {
      setFilteredSites(
        siteListData.data?.results?.features?.map((site: Site) => ({
          label: site.properties?.name,
          value: site.id,
        })) ?? []
      )
    } else {
      setFilteredSites(siteList)
    }
  }, [searchQuery, siteListData.data, siteList])

  return (
    <FormField
      control={form.control}
      name="site"
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel className="font-semibold">
            {t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.selectASite)}
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn('w-full justify-between', !field.value && 'text-muted-foreground')}
                >
                  {field.value
                    ? filteredSites.find((site) => site.value === field.value)?.label
                    : i18next.t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.selectASite)}
                  <ChevronDown className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[310px] p-0">
              <Command>
                <CommandInput
                  placeholder={t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.searchASite)}
                  value={searchQuery}
                  onValueChange={(e) => setSearchQuery(e)}
                />
                <CommandList>
                  {siteListData.isLoading ? (
                    <CommandItem>{t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.loading)}</CommandItem>
                  ) : filteredSites.length === 0 ? (
                    <CommandEmpty>
                      {t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.noSiteFound)}
                    </CommandEmpty>
                  ) : (
                    <CommandGroup>
                      {filteredSites.map((site) => (
                        <CommandItem
                          value={site.label}
                          key={site.value}
                          onSelect={() => form.setValue('site', site.value)}
                        >
                          <Check
                            className={cn('mr-2 size-4', site.value === field.value ? 'opacity-100' : 'opacity-0')}
                          />
                          {site.label}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  )}
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

const DateSelectionField = ({ form }) => {
  return (
    <FormField
      control={form.control}
      name="date"
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel className="font-semibold">
            {t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.choseADate)}
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant={'outline'}
                  className={cn('w-full pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}
                >
                  {field.value ? (
                    format(field.value, 'PP')
                  ) : (
                    <span>{i18next.t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.pickADate)}</span>
                  )}
                  <CalendarIcon className="ml-auto size-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={field.value}
                onSelect={field.onChange}
                disabled={(date) => date < new Date('1900-01-01')}
                initialFocus
              />
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export const CostInputField = ({
  form,
  showDisabledInput,
  value = '',
}: {
  form
  showDisabledInput: boolean
  value?: number | string
}) => {
  const { control } = form

  return (
    <FormField
      control={control}
      name="cost"
      render={({ field }) => {
        const displayValue = showDisabledInput ? `${value ?? ''}€` : field.value

        return (
          <FormItem>
            <FormLabel className="font-semibold">
              {t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.estimatedCost)}
            </FormLabel>

            <FormControl className={showDisabledInput ? 'mt-3' : ''}>
              <Input
                placeholder="€"
                {...field}
                value={displayValue}
                readOnly={showDisabledInput}
                tabIndex={showDisabledInput ? -1 : undefined}
                className={showDisabledInput ? 'bg-gray-200 font-semibold' : ''}
              />
            </FormControl>

            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}

function getTimeLine(timeLine?: string): number[] {
  if (timeLine === 'All') {
    return Array.from({ length: 12 }, (_, i) => i + 1)
  }
  return timeLine ? timeLine.split(',').map(Number) : []
}

export const InitiativeCard = ({ initiative }: { initiative: Initiative }) => {
  const { t } = useTranslation()
  const initiativeCategory = initiative.category
    ? INITIATIVE_CATEGORIES[initiative.category]
    : DEFAULT_INITIATIVE_CATEGORY

  return (
    <Card
      className="flex w-full flex-col justify-between overflow-hidden bg-white text-sm"
      style={{ borderColor: initiativeCategory.color + '95' }}
    >
      <img
        src={initiative.image1}
        className="h-36 w-full rounded-t-md object-cover opacity-90"
        alt="initiative nature"
      />
      <div className="flex flex-row px-6 pt-6">
        <div
          className="flex size-[26px] items-center justify-center rounded-xl border"
          style={{ borderColor: initiativeCategory.color, backgroundColor: initiativeCategory.color + '10' }}
        >
          {initiativeCategory.iconName && (
            <CategoryIcon name={initiativeCategory.iconName} color={initiativeCategory.color} className="size-4" />
          )}
        </div>
        <div className="flex flex-col justify-between px-2 pt-[2px]">
          <CardTitle className="text-lg font-bold leading-5 text-gray-800">{initiative.short_name}</CardTitle>
          <CardDescription className="mt-1 text-xs" style={{ color: initiativeCategory.color }}>
            {t(initiativeCategory.labelKey)}
          </CardDescription>
        </div>
      </div>
      <div className="flex flex-col justify-end p-6">
        <Separator />
        <div className="pb-3 pt-6">{t(i18nKeys.beeoinitiative.common.idealInstallationPeriod)}</div>
        <MonthlySchedule monthsToColor={getTimeLine(initiative.time_line || 'null')} />
      </div>
    </Card>
  )
}
