import { useEffect, useMemo, useRef, useState } from 'react'
import { useSitesList } from '@/client/backend/api/sites/sites'
import { Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import BreadcrumbComponent from '@/pages/beeoinitiative/breadcrumb-component'
import SitesCard from '@/pages/beeoinitiative/sites/sites-card'
import SitesTableBeeo from '@/pages/beeoinitiative/sites/sites-table-beeo'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Skeleton } from '@/components/ui/skeleton'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import SiteFilter from '@/components/beeoinitiative/site-filter'
import SitesMap from '@/components/beeoinitiative/sites-map'
import { Icons } from '@/components/icons'
import NoSitesMessage from '@/components/no-sites-message'
import { PageHeaderHeading } from '@/components/page-header'

const InitiativeSitesPage = () => {
  const { t } = useTranslation()
  const [nameFilter, setNameFilter] = useState('')
  const [selectedName, setSelectedName] = useState('all')
  const [hoveredCard, setHoveredCard] = useState<number | null>(null)
  const hoveredCardRef = useRef<number | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: siteList, isLoading: isLoadingSites } = useSitesList({
    include: 'actions-taken,latest_action_taken_date,initiative_score',
    is_in_initiative: true,
  })

  const getFilteredCards = useMemo(() => {
    const allSites = siteList?.results?.features ?? []

    const filteredActiveSites = allSites.filter((site) => (site?.properties?.actions_taken?.length ?? 0) > 0)
    const filteredInactiveSites = allSites.filter((site) => (site?.properties?.actions_taken?.length ?? 0) === 0)

    let filteredSites: Site[] = []

    if (selectedName === 'activeSites') {
      filteredSites = filteredActiveSites
    } else if (selectedName === 'inactiveSites') {
      filteredSites = filteredInactiveSites
    } else {
      filteredSites = allSites
    }

    if (nameFilter) {
      filteredSites = filteredSites.filter((site) =>
        site?.properties?.name?.toLowerCase().includes(nameFilter.toLowerCase())
      )
    }

    return filteredSites
  }, [siteList, nameFilter, selectedName])

  useEffect(() => {
    const inactiveSitesParam = searchParams.get('inactiveSites')
    if (inactiveSitesParam) {
      setSelectedName('inactiveSites')
    }
  }, [searchParams])

  return (
    <div className="pl-11">
      <BreadcrumbComponent
        breadcrumbItems={[
          {
            label: t(i18nKeys.beeoinitiative.dashboard.breadcrumb.beeoapps),
            href: '/',
          },
          {
            label: t(i18nKeys.beeoinitiative.dashboard.breadcrumb.beeoinitiative),
            href: getPath(Routes.BEEOINITIATIVE),
          },
        ]}
        currentPage={t(i18nKeys.beeoinitiative.common.breadcrumb.sites)}
      />
      <div className="flex flex-col">
        <div className="flex flex-col justify-between ">
          <Tabs defaultValue="cards">
            <div className="flex flex-row items-center justify-start pr-11">
              <div className="flex w-full flex-row items-center justify-between">
                <div>
                  <PageHeaderHeading className="pb-3 text-5xl">
                    {t(i18nKeys.beeoinitiative.sites.title)}
                  </PageHeaderHeading>
                  <div className="pb-6 text-xl">
                    {t(i18nKeys.beeoinitiative.sites.total)}: {getFilteredCards?.length}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-center space-x-5 pt-6">
                  <SiteFilter
                    nameFilter={nameFilter}
                    setNameFilter={setNameFilter}
                    setSelectedName={setSelectedName}
                    setSearchParams={setSearchParams}
                    selectedName={selectedName}
                  />
                  <div>
                    <TabsList className="flex rounded-full">
                      <TabsTrigger
                        value="cards"
                        className="text-black data-[state=active]:rounded-full data-[state=active]:bg-black data-[state=active]:text-white"
                      >
                        <Icons.MapPin />
                      </TabsTrigger>
                      <TabsTrigger
                        value="list"
                        className="text-black data-[state=active]:rounded-full data-[state=active]:bg-black data-[state=active]:text-white"
                      >
                        <Icons.Grid2X2 />
                      </TabsTrigger>
                    </TabsList>
                  </div>
                </div>
              </div>
            </div>

            <TabsContent value="cards">
              {siteList?.results?.features?.length === 0 ? (
                <NoSitesMessage className="m-14 text-center" />
              ) : (
                <div className="flex flex-row justify-center gap-x-5">
                  <ScrollArea className="flex grow">
                    <div className="grid  grow grid-cols-2 gap-5  pb-4 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3">
                      {isLoadingSites
                        ? Array.from({ length: 9 }).map((_, index) => (
                            <Skeleton key={index} className="min-h-[280px] w-full max-w-[360px]" />
                          ))
                        : getFilteredCards?.map((site) => (
                            <SitesCard
                              key={site.id}
                              site={site}
                              onSiteHover={() => setHoveredCard(site.id)}
                              onSiteHoverOut={() => setHoveredCard(null)}
                            />
                          ))}
                    </div>
                  </ScrollArea>
                  <div className="sticky top-16 h-[calc(100vh-100px)] md:w-0 lg:w-[400px] xl:w-[504.1px] 2xl:w-[504.1px] ">
                    <SitesMap
                      sites={getFilteredCards}
                      hoveredCard={hoveredCard}
                      hoveredCardRef={hoveredCardRef}
                      isLoading={isLoadingSites}
                    />
                  </div>
                </div>
              )}
            </TabsContent>
            <TabsContent value="list" className="pb-4 pr-11">
              <SitesTableBeeo data={getFilteredCards} />
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default InitiativeSitesPage
