import { useMemo, useState } from 'react'
import { Initiative, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import BreadcrumbComponent from '@/pages/beeoinitiative/breadcrumb-component'
import { ChevronDown, ChevronUp, Search } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router-dom'

import {
  COSTS,
  filterInitiativeOnSiteResponses,
  IMPACTS,
  INITIATIVE_CATEGORIES,
  PERIODS,
  SITE_TYPES,
} from '@/lib/initiative'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import TextField from '@/components/form/text-field'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'

import InitiativeCard from './initiative-card'
import InitiativesFilters from './initiatives-filters'

const ChevronToggle = ({ isOpen }) => (isOpen ? <ChevronUp className="w-4" /> : <ChevronDown className="w-4" />)

interface InitiativesPageData {
  initiatives: Initiative[]
  site: Site
  siteResponses: Response[] | null
}

const InitiativesPage = () => {
  const { initiatives, site, siteResponses } = useLoaderData() as InitiativesPageData
  const { t } = useTranslation()
  const [isFiltersOpen, setIsFiltersOpen] = useState(false)

  const categoryToFilter = (category) =>
    Object.keys(category).map((key) => ({ value: key, checked: false, ...category[key] }))
  const [filters, setFilters] = useState({
    name: '',
    impact: categoryToFilter(IMPACTS),
    cost: categoryToFilter(COSTS),
    site_type: categoryToFilter(SITE_TYPES),
    category: categoryToFilter(INITIATIVE_CATEGORIES),
    time_line: categoryToFilter(PERIODS),
  })

  const filterValues = (filterName) => (initiative) => {
    const checkedValues = filters[filterName].filter((f) => f.checked)
    if (checkedValues.length) {
      return checkedValues.some((filter) => initiative[filterName] == filter.value)
    }
    return true
  }

  const filteredInitiatives = useMemo(
    () =>
      initiatives
        .filter((initiative) => {
          if (siteResponses) {
            return filterInitiativeOnSiteResponses(initiative, siteResponses)
          }
          return true
        })
        .filter((initiative) => {
          if (filters.name) {
            return (
              initiative.full_name?.toLowerCase().includes(filters.name.toLowerCase()) ||
              initiative.short_name?.toLowerCase().includes(filters.name.toLowerCase()) ||
              initiative.category?.toLowerCase().includes(filters.name.toLowerCase())
            )
          }
          return true
        })
        .filter((initiative) => {
          if (initiative.time_line === 'All') {
            return true
          }
          return filterValues('time_line')(initiative)
        })
        .filter(filterValues('impact'))
        .filter(filterValues('cost'))
        .filter(filterValues('site_type'))
        .filter(filterValues('category')),
    [initiatives, filters, siteResponses]
  )

  const setName = (name) => setFilters({ ...filters, name })

  const getBreadcrumbItems = () => {
    const breadcrumbItems = [
      {
        label: t(i18nKeys.beeoinitiative.dashboard.breadcrumb.beeoapps),
        href: '/',
      },
      {
        label: t(i18nKeys.beeoinitiative.dashboard.breadcrumb.beeoinitiative),
        href: getPath(Routes.BEEOINITIATIVE),
      },
    ]

    if (site) {
      breadcrumbItems.push({
        label: t(i18nKeys.beeoinitiative.common.breadcrumb.sites),
        href: getPath(Routes.BEEOINITIATIVE_SITE),
      })
      breadcrumbItems.push({
        label: `site ${site?.properties?.name}`,
        href: getPath(Routes.BEEOIMPACT_SITE, { params: { siteId: site.id } }),
      })
    }
    return breadcrumbItems
  }

  return (
    <div className="container flex size-full flex-1 flex-col gap-y-2 pb-10">
      <PageHeader className="mb-0">
        <BreadcrumbComponent
          breadcrumbItems={getBreadcrumbItems()}
          currentPage={t(i18nKeys.beeoinitiative.common.breadcrumb.initiatives)}
        />

        <PageHeaderHeading className="mt-4">
          {site ? (
            <>
              {t(i18nKeys.beeoinitiative.initiativesPage.page.title)} {site?.properties?.name}
            </>
          ) : (
            t(i18nKeys.beeoinitiative.initiativesPage.page.titleAll)
          )}
        </PageHeaderHeading>
      </PageHeader>
      <div className="mb-3 flex flex-row items-center justify-between">
        <div className="text-lg">
          {t(i18nKeys.beeoinitiative.initiativesPage.page.total)}: {filteredInitiatives.length}
        </div>
        <div className="flex flex-row gap-4 self-end">
          <TextField
            className="sm:w-96"
            value={filters.name}
            onChange={setName}
            placeholder={t(i18nKeys.beeoinitiative.initiativesPage.page.searchInitiative)}
            startIcon={<Search className="size-5 text-gray-400" aria-hidden="true" />}
          />
          <Button
            variant="outline"
            className="flex flex-row items-center gap-1"
            onClick={() => setIsFiltersOpen(!isFiltersOpen)}
          >
            <span>{t(i18nKeys.beeoinitiative.initiativesPage.page.filterInitiatives)}</span>
            <ChevronToggle isOpen={isFiltersOpen} />
          </Button>
        </div>
      </div>

      {isFiltersOpen && (
        <>
          <Separator className="mb-6 bg-gray-800" />
          <InitiativesFilters filters={filters} setFilters={setFilters} />
          <Separator className="my-10 bg-gray-800" />
        </>
      )}
      <>
        {filteredInitiatives.length === 0 ? (
          <div className="mb-[100px] mt-[50px] w-full text-center text-lg text-gray-500">
            {t(i18nKeys.beeoinitiative.initiativesPage.page.noInitiative)}
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-5 xl:grid-cols-3  2xl:grid-cols-4">
            {filteredInitiatives.map((initiative) => (
              <InitiativeCard initiative={initiative} key={initiative.id} site={site} cardHeight="h-[400px]" />
            ))}
          </div>
        )}
      </>
    </div>
  )
}

export default InitiativesPage
