import { actionsTakenRetrieve } from '@/client/backend/api/actions-taken/actions-taken'
import { initiativesList, initiativesRetrieve } from '@/client/backend/api/initiatives/initiatives'
import { questionsList } from '@/client/backend/api/questions/questions'
import { sitesResponsesList, sitesRetrieve } from '@/client/backend/api/sites/sites'

export const initiativeDetailsLoader = async ({ params }) => {
  const { initiativeId, siteId, actionTakenId } = params

  const [site, initiative, actionTaken, initiatives, responses, questions] = await Promise.all([
    siteId ? sitesRetrieve(siteId) : Promise.resolve(null),
    initiativesRetrieve(initiativeId),
    actionTakenId ? actionsTakenRetrieve(actionTakenId) : Promise.resolve(null),
    initiativesList(),
    siteId ? sitesResponsesList(siteId) : Promise.resolve(0),
    questionsList(),
  ])

  return { site, initiative, actionTaken, initiatives, responses, questions }
}
