// You can find rounded flags in there https://github.com/HatScripts/circle-flags/tree/gh-pages/flags

import BERoundedFlag from '@/assets/be.svg'
import FRRoundedFlag from '@/assets/fr.svg'
import ITRoundedFlag from '@/assets/it.svg'
import { Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import L, { DivIcon } from 'leaflet'
import { useTranslation } from 'react-i18next'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

import { hoverSiteIcon } from '@/lib/map'

type SiteMapProps = {
  target: L.LatLng
  site: Site
}

const SiteMap = ({ target, site }: SiteMapProps) => {
  const { t } = useTranslation()

  const country = site.properties?.country

  const countries = {
    FR: t(i18nKeys.beeoinitiative.sites.table.FR),
    BE: t(i18nKeys.beeoinitiative.sites.table.BE),
    IT: t(i18nKeys.beeoinitiative.sites.table.IT),
  }
  const countryMaps = {
    BE: BERoundedFlag,
    FR: FRRoundedFlag,
    IT: ITRoundedFlag,
  }

  const badgeIcon = new DivIcon({
    html: `
    <div class="absolute left-24 bottom-10 
            md:-translate-x-[170%] 
            lg:-translate-x-[150%] 
            xl:-translate-x-[90%] 
            2xl:-translate-x-[40%] 
            bg-white text-black text-center px-2 py-1 rounded-xl shadow-md flex items-center space-x-2 text-xs">
      <img src=${countryMaps[country ?? '']} width="16">
      <span>${countries[country ?? '']}</span>
    </div>
    `,
    iconSize: [0, 0],
  })

  return (
    <MapContainer
      className="relative z-0 h-full rounded-lg"
      center={target}
      zoom={5}
      dragging={false}
      scrollWheelZoom={false}
      attributionControl={false}
      zoomControl={false}
    >
      <TileLayer key="tile" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      <Marker position={target} icon={hoverSiteIcon('pin')}>
        <Marker position={target} icon={badgeIcon} />{' '}
      </Marker>
    </MapContainer>
  )
}

export default SiteMap
